import { ZawodyService } from './../zawody.service';
import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {RaceInfo, RaceOnList} from './race-info';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {NoweZawodyComponent} from './nowe-zawody/nowe-zawody.component';
import {MainComponent} from '../main/main.component';

@Component({
  selector: 'app-zawody',
  templateUrl: './zawody.component.html',
  styleUrls: ['./zawody.component.css']
})
export class ZawodyComponent implements OnInit {
  aRaceList: RaceOnList[];
  @Input()
  editEnabled = false;
  displayColumns = ['name', 'from_date', 'to_date', 'reg_start', 'reg_end'];
  columnHeaders = {
    name: 'Nazwa',
    from_date: 'Od dnia',
    to_date: 'Do dnia',
    reg_start: 'Rejestracja od',
    reg_end: 'Rejestracja do',
    d100: '100m',
    d300: '300m',
    d500: '500m',
    d1000: '1000m',
    d1500: '1500m',
    d3000: '3000m',
    d5000: '5000m',
    d10000: '10000m'
  };

  dataSource = new MatTableDataSource<RaceOnList>();
  newRaceId = 'nowe-zawody-dialog';

  constructor(private wsa: ZawodyService, private dialog: MatDialog, private mainComponent: MainComponent) { }

  ngOnInit(): void {
    this.listRaces();
  }

  getTableColumns(): string[] {
    const cols = this.displayColumns.slice();
    if ( this.editEnabled ) {
      cols.push('action');
    }
    cols.push('regs');
    return cols;
  }

  listRaces(): void {
    this.wsa.get<RaceOnList[]>('races', {}).subscribe(
      obj => {
        this.aRaceList = obj;
        this.dataSource.data = this.aRaceList;
      },
      err => {
        this.wsa.log( '[listRaces] error: ' + JSON.stringify(err));
        if ( err.status === 401 ) {
          location.href = '/';
        }
      }
    );
  }

  addDialog(): void {
    const dialogConfig = new MatDialogConfig();
    this.wsa.get<RaceInfo>('race/new', {}).subscribe(
      ret => {
        dialogConfig.data = { oper: 'ADD', title: 'Nowe zawody', race: ret  };
        dialogConfig.autoFocus = true;
        const dlgRef = this.dialog.open(NoweZawodyComponent, dialogConfig);
        dlgRef.afterClosed().subscribe( result => {
          if ( result !== undefined && result.oper === 'ADD' ) {
            const race = new RaceOnList();
            Object.assign( race, result.race);
            this.aRaceList.push( race );
            this.dataSource.data = this.aRaceList;
          }
        });
      },
      err => {
        this.wsa.showError(err);
      }
    );
  }

  editDialog( obj: RaceOnList ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { oper: 'UPD', title: 'Edycja zawodów', race: JSON.parse(JSON.stringify(obj))  };
    dialogConfig.autoFocus = true;
    const dlgRef = this.dialog.open(NoweZawodyComponent, dialogConfig);
    dlgRef.afterClosed().subscribe( result => {
      if ( result !== undefined && result.oper === 'UPD' ) {
        Object.assign(obj, result.race);
      }
    });
  }

  registerPlayers( race: RaceOnList ): void {
    const cols: string[] = [];
    Object.keys( race ).forEach( col => {
      if ( col.startsWith('d') ) {
        if (race[col] === true) {
          cols.push(col);
        }
      }
    });
    this.mainComponent.setModule('regs', -1, race.id, cols);
  }

  exportToCsv( id: number ): void {
    this.wsa.exportRaceCsv(id);
  }
}
