<div class="form">
  <div *ngIf="email; else ">
    <h2>Ustawienie hasła dla użytkownika</h2>
    <p>{{email}}</p>
    <div class="w800">
      <mat-form-field>
      <input matInput placeholder="Nowe hasło" type="password" [disabled]="editDisabled" [(ngModel)]="pass0" (ngModelChange)="onChange()" />
      </mat-form-field>
    </div>
    <div class="w800">
      <mat-form-field>
        <input matInput type="password" placeholder="Weryfikacja hasła" [disabled]="editDisabled" [(ngModel)]="pass2" (ngModelChange)="onChange()" />
      </mat-form-field>
    </div>
    <p><button mat-raised-button [disabled]="sendDisabled" (click)="setPass()">Ustaw hasło</button></p>
    <p><button mat-raised-button (click)="setBack()">Powrót do logowania</button></p>
  </div>
  <div *ngIf="errinfo">
    {{errinfo}}
  </div>
  <ng-template #noEmail>
    <div>
      <h2>
        Oczekiwanie na weryfikację....
      </h2>
    </div>
  </ng-template>
</div>
