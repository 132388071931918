export class ClubInfo {
  id: number;
  nr_pzls: number;
  nazwa: string;
  miasto: string;
  wojewodztwo: string;
  licencja: string;
  waznosc: string;
  email?: string;
  sendEmail?: boolean;
}
