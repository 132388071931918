<h2 mat-dialog-title>{{dlgData.title}}</h2>
<mat-dialog-content>
  <mat-form-field>
    <input matInput required type="text" placeholder="Nazwa zawodów" [(ngModel)]="dlgData.race.name">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="date" placeholder="Data od" [(ngModel)]="dlgData.race.from_date">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="date" placeholder="Data do" [(ngModel)]="dlgData.race.to_date">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="datetime-local" placeholder="Rejestracja od" [(ngModel)]="dlgData.race.reg_start">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="datetime-local" placeholder="Rejestracja do" [(ngModel)]="dlgData.race.reg_end">
  </mat-form-field><br>
  <mat-label>Dystanse</mat-label>
  <table>
    <tr><td>100m</td><td>300m</td><td>500m</td><td>1000m</td><td>1500m</td><td>3000m</td><td>5000m</td><td>10000m</td></tr>
    <tr>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d100"></mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d300"></mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d500"></mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d1000"></mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d1500"></mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d3000"></mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d5000"></mat-checkbox></td>
      <td><mat-checkbox [(ngModel)]="dlgData.race.d10000"></mat-checkbox></td>
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="closeModal(true)">Zapisz</button>
  <button mat-raised-button (click)="closeModal(false)">Anuluj</button>
</mat-dialog-actions>
