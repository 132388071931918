<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
  <mat-form-field>
    <input matInput required type="text" placeholder="Nazwa klubu" [(ngModel)]="clubInfo.nazwa">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="text" placeholder="Miasto" [(ngModel)]="clubInfo.miasto">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="text" placeholder="Województwo" [(ngModel)]="clubInfo.wojewodztwo">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="text" placeholder="Licencja" [(ngModel)]="clubInfo.licencja">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="text" placeholder="Ważność" [(ngModel)]="clubInfo.waznosc">
  </mat-form-field><br>
  <mat-form-field>
    <input matInput type="text" placeholder="e-mail" [(ngModel)]="clubInfo.email">
  </mat-form-field><br>
</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="disabledButton" class="mat-raised-button" (click)="anuluj()">Anuluj</button>
  <button [disabled]="disabledButton" class="mat-raised-button" (click)="zapiszKlub(false)">Zapisz</button>
  <button [disabled]="disabledButton" class="mat-raised-button" (click)="zapiszKlub(true)">Zapisz + wyślij maila</button>
</mat-dialog-actions>
