import { MaterialModule } from './material/material.module';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, enableDebugTools } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { KlubyComponent } from './kluby/kluby.component';
import { ZawodnicyComponent } from './zawodnicy/zawodnicy.component';
import { ZawodyComponent } from './zawody/zawody.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SetPasswdComponent } from './set-passwd/set-passwd.component';
import { MainComponent } from './main/main.component';
import { NoweZawodyComponent } from './zawody/nowe-zawody/nowe-zawody.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KlubDialogComponent } from './kluby/klub-dialog/klub-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ZawodnikDialogComponent } from './zawodnicy/zawodnik-dialog/zawodnik-dialog.component';
import { RegPlayerComponent } from './reg-player/reg-player.component';

const routes: Routes = [
  {path: 'reset-password/:uid', component: SetPasswdComponent},
  {path: 'players', component: ZawodnicyComponent},
  {path: '', component: MainComponent},
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    KlubyComponent,
    ZawodnicyComponent,
    ZawodyComponent,
    SetPasswdComponent,
    MainComponent,
    NoweZawodyComponent,
    KlubDialogComponent,
    ZawodnikDialogComponent,
    RegPlayerComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        MatCheckboxModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

