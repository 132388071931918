import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthInfo, ZawodyService } from '../zawody.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  isauth = false;
  authInfo: AuthInfo;
  module = 'zawody';

  constructor(private wsa: ZawodyService, private rt: ActivatedRoute ) { }

  ngOnInit(): void {
    this.wsa.bindAuthInfo().subscribe(
      obj => {
        this.authInfo = obj;
        if ( obj.authorized !== null ) {
          this.isauth = obj.authorized;
          this.wsa.selectedClub = obj.club_id;
        }
      },
      err => {
        this.isauth = false;
      }
    );
  }

  setModule( mod: string, klub: number = -1, race: number = -1, raceDists: string[] = [] ): void {
    if ( klub !== -1 ) {
      this.wsa.selectedClub = klub;
    }
    this.wsa.selectedRace = race;
    this.wsa.raceDists = raceDists;
    this.module = mod;
  }

  logout(): void {
    this.wsa.logoutUser();
  }

  getColorModule( mod: string ): string {
    if ( this.module === mod ) {
      return 'primary';
    }
    return '';
  }
}
