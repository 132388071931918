<h3>Wybrani zawodnicy</h3>
<div class="mat-elevation-z8 part">
  <table mat-table class="lessons-table" [dataSource]="dsRegs">
    <ng-container *ngFor="let col of aColsRegs" matColumnDef="{{col}}">
      <th mat-header-cell *matHeaderCellDef >{{aColsHdrs[col]}}</th>
      <td mat-cell *matCellDef="let element">{{element[col]}}</td>
    </ng-container>
    <ng-container *ngFor="let col of getDistCols()" matColumnDef="{{col}}">
      <th mat-header-cell *matHeaderCellDef >{{aColsHdrs[col]}}</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [(ngModel)]="element[col]" (change)="updatePlayerDist(element)"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="unselectPLayer(element)">Usuń</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getRaceCols(); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: getRaceCols();"></tr>
  </table>
</div>
<h3>Zawodnicy do wyboru</h3>
<div class="part mat-elevation-z8">
  <table mat-table class="lessons-table" [dataSource]="dsPlrs">
    <ng-container *ngFor="let col of aColsPlrs" matColumnDef="{{col}}">
      <th mat-header-cell *matHeaderCellDef >{{aColsHdrs[col]}}</th>
      <td mat-cell *matCellDef="let element">{{element[col]}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="selectPlayer(element)">Wybierz</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getListCols(); sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: getListCols();"></tr>
  </table>
</div>
