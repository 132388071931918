<mat-spinner *ngIf="isLoading"
  mode="indeterminate"
  color="warn"
  style="display: block; margin: 0 auto; z-index: 5000;"
></mat-spinner>
<mat-form-field floatPlaceholder="never" color="accent">
  <input matInput [(ngModel)]="filter" (keyup)="filterData()" placeholder="Wyszukiwanie...">
</mat-form-field>
<table id="tab" hidden mat-table class="lessons-table mat-elevation-z8" [dataSource]="dataSource" matSort (matSortChange)="setSort($event)">
    <ng-container *ngFor="let col of displayColumns" matColumnDef="{{col}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col}}">{{colsHeaders[col]}}</th>
      <td mat-cell *matCellDef="let element"> {{element[col]}} </td>
    </ng-container>
    <ng-container matColumnDef="header-row-dane">
      <th mat-header-cell *matHeaderCellDef
        [style.text-align]="center"
        [attr.colspan]="6">
        Dane
      </th>
    </ng-container>
    <ng-container matColumnDef="header-row-rz">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="8" [style.text-align]="center"> Rekordy życiowe </th>
    </ng-container>
    <ng-container matColumnDef="header-row-rs">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="8" [style.text-align]="center"> Rekordy sezonu </th>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef><button mat-raised-button (click)="nowyZawodnik()">Nowy zawodnik</button></th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="zmienZawodnik(element)">Zmień</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getColumnsList()"></tr>
    <tr mat-row *matRowDef="let row; columns: getColumnsList();"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10,20,50,100]" showFirstLastButoon ></mat-paginator>
