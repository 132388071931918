<div id="container">
  <div *ngIf="isauth === true ; then appTemplate else loginTemplate">
  </div>
  <ng-template #appTemplate>
    <div id="menu">
      <table>
        <tr>
        <td *ngIf="authInfo.club_id === -1"><button mat-raised-button [color]="getColorModule('kluby')" (click)="setModule('kluby')">Kluby</button></td>
        <td *ngIf="authInfo.club_id !== -1"><button mat-raised-button [color]="getColorModule('zawodnicy')" (click)="setModule('zawodnicy', authInfo.club_id )">Zawodnicy</button></td>
        <td><button mat-raised-button [color]="getColorModule('zawody')" (click)="setModule('zawody')">Zawody</button></td>
        <td><button mat-raised-button (click)="logout()">Wyloguj</button></td>
        <tr>
      </table>
    </div>
    <div id="clubInfo" *ngIf="authInfo.club_id !== -1">
      <p>
        Klub: {{authInfo.club_name}}, miasto: {{authInfo.club_city}}
      </p>
    </div>
    <div *ngIf="module === 'kluby'">
      <app-kluby [editEnabled]="authInfo.club_id === -1"></app-kluby>
    </div>
    <div *ngIf="module === 'zawodnicy'">
      <app-zawodnicy></app-zawodnicy>
    </div>
    <div *ngIf="module === 'zawody'">
      <app-zawody [editEnabled]="authInfo.club_id === -1"></app-zawody>
    </div>
    <div *ngIf="module === 'regs'">
      <app-reg-player></app-reg-player>
    </div>
  </ng-template>
  <ng-template #loginTemplate>
    <app-login></app-login>
  </ng-template>
</div>
