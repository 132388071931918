export class RaceInfo {
  id: number;
  name: string;
  from_date: string;
  to_date: string;
  reg_start: string;
  reg_end: string;
  d100: boolean;
  d300: boolean;
  d500: boolean;
  d1000: boolean;
  d1500: boolean;
  d3000: boolean;
  d5000: boolean;
  d10000: boolean;
};

export class RaceOnList extends RaceInfo {
  selected = false;
}
