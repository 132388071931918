import { ZawodyService } from './../zawody.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-set-passwd',
  templateUrl: './set-passwd.component.html',
  styleUrls: ['./set-passwd.component.css']
})
export class SetPasswdComponent implements OnInit {
  email: string;
  pass0: string;
  pass2: string;
  uid: string;
  errinfo = 'Weryfikacja adresu e-mail';
  sendDisabled = true;
  editDisabled = true;
  constructor( private wsa: ZawodyService, private art: ActivatedRoute, private rtr: Router ) { }

  ngOnInit(): void {
    this.uid = this.art.snapshot.paramMap.get('uid');
    this.wsa.log( "[set-passwd] uid: " + this.uid);
    this.wsa.getResetPassEmail(this.uid).subscribe(
      obj => {
        this.wsa.log( " email => " + obj.email );
        this.email = obj.email;
        this.editDisabled = false;
        this.errinfo = '';
      },
      err => {
        if ( err.error && err.error.detail ) {
          this.errinfo = err.error.detail;
        } else {
          this.errinfo = JSON.stringify(err);
        }
        this.wsa.log( "error " + this.errinfo);
      }
    );
  }

  onChange(): void {
    let ok = true;
    if ( this.pass2 ) {
      if ( this.pass0 !== this.pass2 ) {
        this.errinfo = 'Hasła nie są zgodne';
        ok = false;
      }
    } else {
      ok = false;
    }
    this.sendDisabled = !ok;
  }

  setPass(): void {
    this.editDisabled = true;
    this.sendDisabled = true;
    this.wsa.setPassword( this.uid, this.email, this.pass0 ).subscribe(
      obj => {
        this.errinfo = obj.info;
        if ( obj.send ) {
          interval(1500).subscribe(
            _ => { this.setBack(); }
          );
        }
      },
      err => {
      }
    );
  }

  setBack(): void {
    this.rtr.navigateByUrl('/');
  }
}
