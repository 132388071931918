<div class="form">
  <h2>Logowanie użytkownika</h2>
  <div class="w800">
    <mat-form-field>
      <input matInput required placeholder="e-mail użytkownika:" [(ngModel)]="email">
    </mat-form-field>
    <br>
    <mat-form-field>
      <input matInput required placeholder="Hasło:" type="password" [(ngModel)]="password">
    </mat-form-field>
  </div>
  <div *ngIf="loginInfo !== ''" class="w800">
    {{loginInfo}}
  </div>
  <div class="w800">
      <button mat-raised-button [disabled]="loginDisabled()" (click)="login()" type="submit">Zaloguj</button>
  </div>
  <div *ngIf="resPassInfo === ''" class="w800">
      <button mat-raised-button (click)="resetPassword()">Przypomnij hasło</button>
  </div>
  <div *ngIf="resPassInfo !== ''" class="w800">
    {{resPassInfo}}
  </div>
<div>
