import { ZawodyService, AuthInfo } from './../zawody.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  email: string = '';
  password: string = '';
  resPassInfo = '';
  loginInfo = '';
  wait4login = false;
  wait4reset = false;

  constructor( private wsa: ZawodyService ) {
  }

  ngOnInit(): void {
    this.wsa.bindAuthInfo().subscribe( (obj: AuthInfo) => {
      if ( obj ) {
        if ( this.wait4login ) {
          if (obj.errinfo) {
            this.loginInfo = obj.errinfo;
          } else {
            this.loginInfo = '';
          }
          this.password = '';
          this.wait4login = false;
        } else if ( this.wait4reset ) {
          if (obj.errinfo) {
            this.resPassInfo = obj.errinfo;
          } else {
            this.resPassInfo = '';
          }
        }
      }
    } );
    this.wsa.chackSession();
  }

  login(): void {
    this.wait4login = true;
    this.wsa.loginUser( this.email, this.password );
  }

  resetPassword(): void {
    this.wait4reset = true;
    this.wsa.resetPassword(this.email);
  }

  loginDisabled(): boolean {
    return this.email === null || this.email === '' || this.password === null || this.password === '';
  }

  editDisabled(): boolean {
    return this.wait4login;
  }

  onChange(): void {
    if (this.loginInfo !== '') {
      this.loginInfo = '';
    }
  }
}
