<h2>{{title}}</h2>
<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Dane osobowe">
      <mat-form-field>
        <input matInput required placeholder="Nazwisko" [(ngModel)]="player.nazwisko">
      </mat-form-field><br>
      <mat-form-field>
        <input matInput required placeholder="Imię" [(ngModel)]="player.imie">
      </mat-form-field><br>
      <mat-form-field>
        <input matInput required placeholder="Data urodzenia" type="date" [(ngModel)]="player.data_ur">
      </mat-form-field><br>
      <mat-form-field>
        <input matInput placeholder="Licencja" [(ngModel)]="player.licencja">
      </mat-form-field><br>
      <mat-form-field appearance="fill">
        <mat-label>Płeć</mat-label>
        <mat-select required [(ngModel)]="player.plec" value="K">
          <mat-option value="K">Kobieta</mat-option>
          <mat-option value="M">Mężczyzna</mat-option>
        </mat-select>
      </mat-form-field><br>
      <mat-form-field>
        <input matInput placeholder="Kategoria" [(ngModel)]="player.kategoria">
      </mat-form-field><br>
      <mat-form-field>
        <input matInput placeholder="Nr zawodnika" [(ngModel)]="player.nr_zawodnika">
      </mat-form-field>
    </mat-tab>
    <mat-tab label="Rekordy życiowe">
      <span class="lbl-rekord">100m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz100" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field><br>
      <span class="lbl-rekord">300m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz300" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">500m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz500" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">1000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz1000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">1500m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz1500" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">3000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz3000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">5000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz5000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">10000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rz10000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
    </mat-tab>
    <mat-tab label="Rekordy sezonu">
      <span class="lbl-rekord">100m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs100" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">300m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs300" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">500m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs500" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">1000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs1000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">1500m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs1500" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">3000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs3000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">5000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs5000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
      <span class="lbl-rekord"></span><br>
      <span class="lbl-rekord">10000m:&nbsp;</span>
      <mat-form-field class="prc80">
        <input matInput [(ngModel)]="player.rs10000" class="right-align-inp">
        <span matSuffix>&nbsp;s</span>
      </mat-form-field>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="closeModal(true)">Zapisz</button>
  <button mat-raised-button (click)="closeModal(false)">Anuluj</button>
</mat-dialog-actions>
