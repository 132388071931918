import { MatTableDataSource } from '@angular/material/table';
import { ZawodyService } from './../zawody.service';
import {Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KlubDialogComponent } from './klub-dialog/klub-dialog.component';
import { ClubInfo } from './club-info';
import { MainComponent } from '../main/main.component';

@Component({
  selector: 'app-kluby',
  templateUrl: './kluby.component.html',
  styleUrls: ['./kluby.component.css']
})
export class KlubyComponent implements OnInit {
  aLstClub: ClubInfo[] = [];
  dataSource = new MatTableDataSource<ClubInfo>(this.aLstClub);
  viewColumns = ['nazwa', 'miasto', 'wojewodztwo', 'licencja', 'waznosc', 'email'];
  colsHeaders = {
    nazwa: 'Nazwa',
    miasto: 'Miasto',
    wojewodztwo: 'Województwo',
    licencja: 'Licencja',
    waznosc: 'Ważność',
    email: 'e-mail'
  };
  isLoading = true;
  filter = '';
  sortCol = '';
  sortDir = '';
  @Input()
  editEnabled;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private wsa: ZawodyService, private dialog: MatDialog, private mainComponent: MainComponent) {
  }

  ngOnInit(): void {
    this.loadClubs();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadClubs(): void {
    this.wsa.get<ClubInfo[]>('clubs', {}).subscribe(
      obj => {
        this.aLstClub = obj;
        this.dataSource.data = obj;
        this.isLoading = false;
        document.getElementById('tab').removeAttribute('hidden');
      },
      err => {
        this.wsa.showError(err);
        this.isLoading = false;
      }
    );
  }

  setSort($event): void {
    this.sortCol = $event.active;
    this.sortDir = $event.direction;
    this.filterData();
  }

  filterData(): void {
    const ftl = this.filter.trim().toLowerCase();
    if (ftl !== '') {
      this.dataSource.data = this.aLstClub.slice().filter(
        (el) => JSON.stringify(el).toLowerCase().indexOf(ftl) > -1
      );
    } else {
      this.dataSource.data = this.aLstClub.slice();
    }
    if (this.sortCol !== '') {
      if ('asc' === this.sortDir) {
        this.dataSource.data = this.dataSource.data.slice().sort(
          (a, b) => a[this.sortCol] > b[this.sortCol] ? -1 : a[this.sortCol] < b[this.sortCol] ? 1 : 0
        );
      } else {
        this.dataSource.data = this.dataSource.data.slice().sort(
          (a, b) => a[this.sortCol] < b[this.sortCol] ? -1 : a[this.sortCol] > b[this.sortCol] ? 1 : 0
        );
      }
    }
  }

  addClubDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Nowy klub',
      club: { id: -1, sendEmail: true }
    };
    const dialogRef = this.dialog.open(KlubDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.hasOwnProperty( 'event') && result.event === 'SAV' ) {
            this.aLstClub.push( result.club );
            this.filter = result.club.nazwa;
            this.filterData();
      }
    });
  }

  editClubDialog( club: ClubInfo ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    club.sendEmail = false;
    dialogConfig.data = {
      title: 'Edycja klubu',
      club
    };
    const dialogRef = this.dialog.open(KlubDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.hasOwnProperty( 'event') && result.event === 'SAV' ) {
        Object.assign( club, result.club );
      }
    });
  }

  zawodnicy( id: number ): void {
    this.mainComponent.setModule('zawodnicy', id);
  }

  getDisplayColumns(): string[] {
    const dc = this.viewColumns.slice();
    dc.push('zawodnicy');
    return dc;
  }
}

