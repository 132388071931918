
export class TPlayerInfo {
  id: number;
  nazwisko: string;
  imie: string;
  data_ur: string;
  licencja?: string;
  plec?: string;
  kategoria?: string;
  rz100?: number;
  rz300?: number;
  rz500?: number;
  rz1000?: number;
  rz1500?: number;
  rz3000?: number;
  rz5000?: number;
  rz10000?: number;
  rs100?: number;
  rs300?: number;
  rs500?: number;
  rs1000?: number;
  rs1500?: number;
  rs3000?: number;
  rs5000?: number;
  rs10000?: number;
  active?: number;
  deleted?: boolean;
  klub?: string;
  load_pzls_time?: string;
  nr_klubu?: number;
  nr_pzls?: number;
  nr_zawodnika?: string;
  szkola?: string;
  club_id?: number;
}
