import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClubInfo} from '../club-info';
import {ZawodyService} from '../../zawody.service';

@Component({
  selector: 'app-klub-dialog',
  templateUrl: './klub-dialog.component.html',
  styleUrls: ['./klub-dialog.component.css']
})
export class KlubDialogComponent implements OnInit {
  formValid: any;
  clubInfo = new ClubInfo();
  title = '';
  disabledButton = false;

  constructor( private fb: FormBuilder, private dialogRef: MatDialogRef<KlubDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: any, private wsa: ZawodyService) {
    this.formValid = this.fb.group( {
      nazwa: ['', Validators.required],
      miasto: ['', Validators.required]
    });
    Object.assign( this.clubInfo, data.club );
    this.title = data.title;
  }

  ngOnInit(): void {
  }

  zapiszKlub( send: boolean ): void {
    this.disabledButton = true;
    this.clubInfo.sendEmail = send;
    if ( this.clubInfo.id === -1 ) {
      this.wsa.post<ClubInfo>('clubs', this.clubInfo).subscribe(
        ret => {
          this.dialogRef.close({event: 'SAV', club: ret});
        },
        err => {
          this.wsa.showError(err);
        }
      );
    } else {
      this.wsa.put<ClubInfo>( 'clubs', this.clubInfo).subscribe(
        ret => {
          this.dialogRef.close( {event: 'SAV', club: ret});
        },
        err => {
          this.wsa.showError(err);
        }
      );
    }
    this.disabledButton = false;
  }

  anuluj(): void {
    this.dialogRef.close({event: 'Cancel'});
  }
}
