import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {BehaviorSubject, Observable, ObservableInput, of, timer} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { stringify } from '@angular/compiler/src/util';
import {TPlayerInfo} from './zawodnicy/player-info';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PostPlayerResp, RegPlayer} from './reg-player/reg-player';

export class AuthInfo {
  access_token?: string;
  token_type?: string;
  authorized?: boolean;
  menu_opt?: string[];
  errinfo?: string;
  email?: string;
  club_id?: number;
  sid?: string;
  club_name?: string;
  club_city?: string;
}

export class ResetInfo {
  send: boolean;
  info: string;
}

export class ResetEmail {
  email: string;
}

export class FileCont {
  content: string[];
}

@Injectable({
  providedIn: 'root'
})

export class ZawodyService {
  apiUrl = 'https://app.arenalodowa.pl/api/zawody/';
  localStorageKey = 'access_token';

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });

  selectedClub = -1;
  selectedRace = -1;
  raceDists: string[] = [];

  authInfo: AuthInfo = {
    email: '',
    club_id: -1,
    sid: '',
    errinfo: '',
    access_token: '',
    token_type: ''
  };

  private authInfoSubject: BehaviorSubject<AuthInfo>;

  constructor( private http: HttpClient, private snackBar: MatSnackBar ) {
    this.authInfoSubject = new BehaviorSubject<AuthInfo>(this.authInfo);
  }

  public log( msg: string ): void {
    console.log( '[ZawodyService]', msg);
  }

  post<T>( url, body, retry = 0 ): Observable<T> {
    this.log( 'post headers: ' + this.dumpHeaders() );
    return this.http
      .post<T>(this.apiUrl + url, body, {headers: this.httpHeaders});
  }

  put<T>( url, body, retry = 0 ): Observable<T> {
    this.log( `PUT url: ${this.apiUrl}${url}`);
    this.log( 'headers: ' + this.dumpHeaders() );
    this.log( `body: ${body}`);
    return this.http
      .put<T>(this.apiUrl + url, body, {headers: this.httpHeaders});
  }

  get<T>( url, params: {}, retry = 0 ): Observable<T> {
    let par = new HttpParams();
    for ( const p of Object.keys(params) ) {
      par = par.append( p, params[p] );
    }
    return this.http
      .get<T>( this.apiUrl + url, {headers: this.httpHeaders, params: par } );
  }

  delete<T>( url, params: {}, retry: number = 0 ): Observable<T> {
    let par = new HttpParams();
    for ( const p of Object.keys(params) ) {
      par = par.append( p, params[p] );
    }
    return this.http.delete<T>( this.apiUrl + url, {headers: this.httpHeaders, params: par});
  }

  private dumpHeaders(): string {
    let s = '';
    for (const k of this.httpHeaders.keys()) {
      s = s + ', ' + k + ' => ' + this.httpHeaders.get(k);
    }
    return s;
  }

  private addAuthToken( token: string ): void {
    // let s = 'token: ' + token + ' to ' + this.dumpHeaders();
    // this.log( s );
    this.httpHeaders = this.httpHeaders.set('Authorization', 'bearer ' + token);
    // s = 'result ' + this.dumpHeaders();
    // this.log( s );
  }

  public loginUser( usr: string, ps: string ): void {
    const body = JSON.stringify( {email: usr, password: ps} );
    this.post<AuthInfo>( 'login', body ).subscribe (
      obj => {
        this.authInfo = obj;
        if ( obj.access_token ) {
          this.addAuthToken( obj.access_token );
          if ( this.localStorageKey in localStorage ) {
            localStorage.removeItem( this.localStorageKey );
          }
          localStorage.setItem( this.localStorageKey, obj.access_token );
        }
        this.authInfoSubject.next( this.authInfo );
      },
      err => {
        if ( err.error && err.error.detail ) {
          this.authInfo.errinfo = err.error.detail;
        } else {
          this.authInfo.errinfo = JSON.stringify(err);
        }
        this.authInfo.authorized = false;
        this.authInfoSubject.next( this.authInfo );
      },
    );
  }

  public logoutUser(): void {
    localStorage.removeItem( this.localStorageKey );
    this.post<any>('logout', {}).subscribe(
      ret => { },
      err => { }
    );
    location.href = '/';
  }

  resetPassword( email: string ): void {
    const body = JSON.stringify({email, rights: location.origin});
    this.post<ResetInfo>('reset-password', body).subscribe(
      obj => {
        this.authInfo.errinfo = obj.info;
        this.authInfoSubject.next( this.authInfo );
      },
      err => {
        if ( err.error && err.error.detail ) {
          this.authInfo.errinfo = err.error.detail;
        } else {
          this.authInfo.errinfo = JSON.stringify(err);
        }
        this.authInfoSubject.next( this.authInfo );
      }
    );
  }

  chackSession(): void {
    if ( this.localStorageKey in localStorage ) {
      const key = localStorage.getItem( this.localStorageKey );
      this.addAuthToken(key);
      this.post<AuthInfo>('session', '{}').subscribe(
        obj => {
          this.authInfo = obj;
          if ( this.authInfo.club_id === null ) {
            this.authInfo.club_id = -1;
          }
          /* console.log( `[ZawodyService/CheckSession] ${JSON.stringify(this.authInfo)}` ); */
          this.authInfoSubject.next( this.authInfo );
        }
      );
    } else {
      this.log( 'no storage key' );
    }
  }

  private handleError<T>( operation = 'operation', result?: T) {
    return ( error: any ): Observable<T> => {
      this.log( stringify(error) );
      return of(result as T);
    };
  }

  bindAuthInfo(): Observable<AuthInfo> {
    return this.authInfoSubject.asObservable();
  }

  getResetPassEmail( uid: string ): Observable<ResetEmail> {
    const body = JSON.stringify( {email: uid } );
    return this.post<ResetEmail>('email-by-uid', body);
  }

  setPassword( uid: string, email: string, passwd: string ): Observable<ResetInfo>  {
    const body = JSON.stringify( {email, hash_pass: passwd, rights: uid });
    return this.post<ResetInfo>('set-password', body);
  }

  listPlayers(): Observable<TPlayerInfo[]> {
    return this.get( `players?club_id=${this.selectedClub}`, {} );
  }

  addPlayer( player: TPlayerInfo ): Observable<TPlayerInfo> {
    player.club_id = this.selectedClub;
    return this.post( `players`, JSON.stringify(player) );
  }

  updatePlayer( player: TPlayerInfo ): Observable<TPlayerInfo> {
    return this.put( 'players', JSON.stringify(player) );
  }

  listRacePlayers(): Observable<RegPlayer[]> {
    return this.get<RegPlayer[]>( 'raceplayer', {club_id: this.selectedClub, race_id: this.selectedRace});
  }

  addRacePlayer( playerId: number ): Observable<PostPlayerResp> {
    return this.post<PostPlayerResp>( 'raceplayer', {race_id: this.selectedRace, player_id: playerId});
  }

  delRacePlayer( playerId: number ): Observable<PostPlayerResp> {
    return this.delete<PostPlayerResp>( 'raceplayer', {race_id: this.selectedRace, player_id: playerId});
  }

  updateRacePlayer( player: RegPlayer): Observable<PostPlayerResp> {
    return this.put<PostPlayerResp>( 'raceplayer', player);
  }

  exportRaceCsv( id: number ): void {
    this.get<FileCont>('raceexport', {race: id}).subscribe(
      data => {
        const a = document.createElement('a');
        const blob = new Blob( [data.content.join('\r\n')], {type: 'text/csv;encoding=UTF-8;'});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'zawody.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    });
  }

  showError( err: any ): void {
    /* this.log(`ERROR: ${JSON.stringify(err)}`); */
    let msg = '';
    let stat = -1;
    if ( typeof (err) === 'string' ) {
      msg = err;
    } else if ( err.error !== undefined && err.status !== undefined ) {
      msg = err.error.detail;
      stat = err.status;
    } else {
      msg = err.stringify();
    }
    this.snackBar.open(  msg, '', {duration: 1300, panelClass: ['warning']} );
    if ( stat === 401 ) {
      timer( 1300 ).subscribe(
        _ => {
          location.href = '/';
        }
      );
    }
  }
}
