import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZawodyService} from '../../zawody.service';
import {RaceInfo} from '../race-info';

@Component({
  selector: 'app-nowe-zawody',
  templateUrl: './nowe-zawody.component.html',
  styleUrls: ['./nowe-zawody.component.css']
})
export class NoweZawodyComponent implements OnInit {
  dlgData = {
    oper: '',
    title: '',
    race: new RaceInfo()
  };
  constructor( public dialogRef: MatDialogRef<NoweZawodyComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any, private wsa: ZawodyService) {
    this.dlgData = data;
  }

  ngOnInit(): void {
  }

  closeModal( withSave: boolean ): void {
    if ( withSave ) {
      if ( this.dlgData.oper === 'ADD' ) {
        this.wsa.post<RaceInfo>( 'races', this.dlgData.race ).subscribe(
          ret => {
            this.dialogRef.close( { oper: this.dlgData.oper, race: ret } );
          },
          err => {
            this.wsa.showError(err);
          });
      } else if ( this.dlgData.oper === 'UPD' ) {
        this.wsa.put<RaceInfo>( `races`, this.dlgData.race ).subscribe(
          ret => {
            this.dialogRef.close( { oper: this.dlgData.oper, race: ret } );
          },
          err => {
            this.wsa.showError(err);
          }
        );
      }
    } else {
      this.dialogRef.close( {oper: 'CAN'} );
    }
  }
}
