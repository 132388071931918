import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TPlayerInfo} from '../player-info';
import {ZawodyService} from '../../zawody.service';

@Component({
  selector: 'app-zawodnik-dialog',
  templateUrl: './zawodnik-dialog.component.html',
  styleUrls: ['./zawodnik-dialog.component.css']
})
export class ZawodnikDialogComponent implements OnInit {
  player = new TPlayerInfo();
  title = '';

  constructor( private dialogRef: MatDialogRef<ZawodnikDialogComponent>,
               @Inject(MAT_DIALOG_DATA) data: any,
               private wsa: ZawodyService ) {
    Object.assign( this.player, data.player );
    this.title = data.title;
  }

  ngOnInit(): void {
  }

  closeModal( save: boolean ): void {
    if ( save ) {
      if ( this.player.imie === undefined || this.player.nazwisko === undefined || this.player.data_ur === undefined ) {
        this.wsa.showError( 'Imię, nazwisko i data urodzenia muszą być wypełnione');
        return;
      }
      if ( this.player.id >= 0 ) {
        this.wsa.updatePlayer( this.player ).subscribe(
          ret => {
            this.dialogRef.close( { oper: 'SAV', data: ret} );
          },
          err => {
            this.wsa.showError( err );
          }
        );
      } else {
        this.wsa.addPlayer( this.player ).subscribe(
          ret => {
            this.dialogRef.close( { oper: 'SAV', data: ret } );
          },
          err => {
            this.wsa.showError( err );
          }
        );
      }
    } else {
      this.dialogRef.close( { oper: 'CAN'});
    }
  }
}
