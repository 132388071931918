<table mat-table [dataSource]="dataSource" class="lessons-table mat-elevation-z8">
  <ng-container *ngFor="let col of displayColumns" matColumnDef="{{col}}">
    <th mat-header-cell *matHeaderCellDef> {{columnHeaders[col]}} </th>
    <td mat-cell *matCellDef="let element"> {{element[col]}} </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef><button *ngIf="editEnabled" mat-raised-button (click)="addDialog()">Dodaj zawody</button></th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="editEnabled" mat-raised-button (click)="editDialog(element)">Zmień</button>
      <button *ngIf="editEnabled" mat-raised-button (click)="exportToCsv(element.id)">Eksport</button>
    </td>
  </ng-container>
  <ng-container matColumnDef="regs">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="editEnabled === false" mat-raised-button (click)="registerPlayers(element)">Wybór zawodników</button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="getTableColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: getTableColumns();"></tr>
</table>

