import {Component, OnInit, ViewChild} from '@angular/core';
import {ZawodyService} from '../zawody.service';
import {RegPlayer} from './reg-player';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-reg-player',
  templateUrl: './reg-player.component.html',
  styleUrls: ['./reg-player.component.css']
})
export class RegPlayerComponent implements OnInit {
  aRegPlayers: RegPlayer[] = [];
  dsRegs = new MatTableDataSource<RegPlayer>( this.aRegPlayers );
  dsPlrs = new MatTableDataSource<RegPlayer>( this.aRegPlayers );

  aColsRegs = [
    'name',
    'category'
  ];
  aColsPlrs = [
    'name',
    'category'
  ];
  aColsHdrs = {
    name: 'Nazwisko i imię',
    category: 'Kategoria',
    d100: '100m',
    d300: '300m',
    d500: '500m',
    d1000: '1000m',
    d1500: '1500m',
    d3000: '3000m',
    d5000: '5000m',
    d10000: '10000m'
  };

  constructor(private wsa: ZawodyService) { }

  ngOnInit(): void {
    this.loadPlayers();
  }

  loadPlayers(): void {
    this.wsa.listRacePlayers().subscribe(
      players => {
        this.aRegPlayers = players;
        this.filterPlayers();
      },
      err => {
        this.wsa.showError( err );
      }
    );
  }

  filterPlayers(): void {
    this.dsRegs.data = this.aRegPlayers.slice().filter( (el: RegPlayer) => el.id != null );
    this.dsPlrs.data = this.aRegPlayers.slice().filter( (el: RegPlayer) => el.id == null );
  }

  getListCols(): string[] {
    const ret = this.aColsPlrs.slice(); /* dwie pierwsze kolumny, nie dystanse */
    ret.push('action');
    return ret;
  }

  getRaceCols(): string[] {
    const ret = this.aColsRegs.slice().concat(this.getDistCols());
    ret.push('action');
    return ret;
  }

  getDistCols(): string[] {
    const ret = this.wsa.raceDists.slice();
    return ret; /* dystanse wybranych zawodów */
  }

  updatePlayerDist( elem: RegPlayer ): void {
    this.wsa.updateRacePlayer(elem).subscribe( obj => {
        /* nie ma potrzeby nic robić... */
      },
      err => {
        this.wsa.showError(err);
      });
  }

  selectPlayer( elem ): void {
    this.wsa.addRacePlayer( elem.player_id ).subscribe( player => {
        Object.assign(elem, player);
        this.filterPlayers();
      },
      err => {
        this.wsa.showError(err);
      });
  }

  unselectPLayer( elem ): void {
    this.wsa.delRacePlayer( elem.player_id ).subscribe( msg => {
        elem.id = null;
        this.filterPlayers();
      },
      err => {
        this.wsa.showError(err);
      }
    );
  }
}
