<mat-spinner *ngIf="isLoading" mode="indeterminate" color="warn" style="display: block; margin: 0 auto; z-index: 5000;"></mat-spinner>
<div [style.width.%]="100">
  <mat-form-field floatPlaceholder="never" color="accent">
    <input matInput [(ngModel)]="filter" (keyup)="filterData()" placeholder="Wyszukiwanie...">
  </mat-form-field>

</div>
<table id="tab" hidden mat-table class="lessons-table mat-elevation-z8" [dataSource]="dataSource" matSort (matSortChange)="setSort($event)">
    <ng-container *ngFor="let col of viewColumns" matColumnDef="{{col}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col}}">{{colsHeaders[col]}}</th>
      <td mat-cell *matCellDef="let element"> {{element[col]}} </td>
    </ng-container>
    <ng-container matColumnDef="zawodnicy">
      <th mat-header-cell *matHeaderCellDef><button *ngIf="editEnabled" mat-raised-button (click)="addClubDialog()">Dodaj klub...</button></th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="editClubDialog(element)">Edycja</button>
        <button mat-raised-button (click)="zawodnicy(element.id)">Zawodnicy</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayColumns();"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10,20,50,100]" showFirstLastButoon></mat-paginator>
