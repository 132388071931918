import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ZawodyService } from './../zawody.service';
import { MatSort } from '@angular/material/sort';
import {TPlayerInfo} from './player-info';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ZawodnikDialogComponent} from './zawodnik-dialog/zawodnik-dialog.component';

@Component({
  selector: 'app-zawodnicy',
  templateUrl: './zawodnicy.component.html',
  styleUrls: ['./zawodnicy.component.css']
})
export class ZawodnicyComponent implements OnInit {
  @Input()
  filter = '';
  sortCol = '';
  sortDir = '';
  aListPlayers: TPlayerInfo[] = [];
  dataSource = new MatTableDataSource<TPlayerInfo>( this.aListPlayers );
  displayColumns = ['nazwisko', 'imie', 'data_ur', 'licencja', 'plec', 'kategoria', 'nr_zawodnika'];
    /* "rz100","rz300","rz500", "rz1000", "rz1500", "rz3000", "rz5000", "rz10000",
    "rs100","rs300","rs500", "rs1000", "rs1500", "rs3000", "rs5000", "rs10000"]; */
  isLoading = true;
  editEnabled = true;
  colsHeaders = {
    nazwisko: 'Nazwisko',
    imie: 'Imię',
    data_ur: 'Data urodzenia',
    licencja: 'Licencja',
    plec: 'Płeć',
    kategoria: 'Kategoria',
    rz100: '100m',
    rz300: '300m',
    rz500: '500m',
    rz1000: '1000m',
    rz1500: '1500m',
    rz3000: '3000m',
    rz5000: '5000m',
    rz10000: '10000m',
    rs100: '100m',
    rs300: '300m',
    rs500: '500m',
    rs1000: '1000m',
    rs1500: '1500m',
    rs3000: '3000m',
    rs5000: '5000m',
    rs10000: '10000m',
    nr_zawodnika: 'Numer zawodnika'
  };

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort)  sort: MatSort;

  constructor( private wsa: ZawodyService, private dialog: MatDialog ) { }

  ngOnInit(): void {
    // console.log('listPlayers init');
    this.editEnabled = (this.wsa.authInfo.club_id === -1);
    this.listPlayers();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  listPlayers(): void {
    // console.log('listPlayers');
    this.wsa.listPlayers().subscribe(
      obj => {
        // console.log( obj );
        this.aListPlayers = obj;
        this.isLoading = false;
        this.dataSource.data = obj;
        document.getElementById('tab').removeAttribute('hidden');
      } ,
      err => {
        this.wsa.showError( err );
        this.isLoading = false;
      }
    );
  }

  setSort( $event ): void {
    this.sortCol = $event.active;
    this.sortDir = $event.direction;
    this.filterData();
  }

  filterData(): void {
    const ftl = this.filter.trim().toLowerCase();
    if ( ftl !== '' ) {
      this.dataSource.data = this.aListPlayers.slice().filter (
        (el) => JSON.stringify(el).toLowerCase().indexOf(ftl) > -1
      );
    } else {
      this.dataSource.data = this.aListPlayers.slice();
    }
    if ( this.sortCol !== '' ) {
      if ( 'asc' === this.sortDir) {
        this.dataSource.data = this.dataSource.data.slice().sort(
          (a, b) => a[this.sortCol] > b[this.sortCol] ? -1 : a[this.sortCol] < b[this.sortCol] ? 1 : 0
        );
      } else {
        this.dataSource.data = this.dataSource.data.slice().sort(
          (a, b) => a[this.sortCol] < b[this.sortCol] ? -1 : a[this.sortCol] > b[this.sortCol] ? 1 : 0
        );
      }
    }
  }

  nowyZawodnik(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: 'Nowy zawodnik'};
    dialogConfig.autoFocus = true;
    const dlgRef = this.dialog.open(ZawodnikDialogComponent, dialogConfig);
    dlgRef.afterClosed().subscribe( result => {
      if ( result.oper === 'SAV') {
        this.aListPlayers.push(result.data);
        this.filterData();
      }
    });
  }

  zmienZawodnik( obj: TPlayerInfo ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { player: obj, title: 'Edycja zawodnika' };
    dialogConfig.autoFocus = true;
    const dlgRef = this.dialog.open(ZawodnikDialogComponent, dialogConfig);
    dlgRef.afterClosed().subscribe( result => {
      if ( result.oper === 'SAV') {
        Object.assign( obj, result.data );
      }
    });
  }

  getColumnsList(): string[] {
    const cols = this.displayColumns.slice();
    if ( this.editEnabled ) {
        cols.push('action');
    }
    return cols;
  }

}
